$("body").on("focusin", "input[type=tel]", (function () {
	$(this).inputmask({
		mask: ["+79999999999", "+799999999999"],
		greedy: !1
	})
}
));

(function () {
	'use strict';
	window.addEventListener('load', function () {
		// Fetch all the forms we want to apply custom Bootstrap validation styles to
		var forms = document.getElementsByClassName('needs-validation');
		// Loop over them and prevent submission
		var validation = Array.prototype.filter.call(forms, function (form) {
			form.addEventListener('submit', function (event) {
				if (form.checkValidity() === false) {
					event.preventDefault();
					event.stopPropagation();
				}
				form.classList.add('was-validated');
			}, false);
		});
	}, false);
})();

$("header").on("click", ".offcanvas-btn", (function () {
	if ($('.offcanvas-end').hasClass('show')) {
		$('.header').css({ "padding-right": "17" })
	}
}
)),

	/*
		$(".modal").on("show.bs.modal", function (e) {
			$("header").css("padding-right", window.innerWidth - document.documentElement.clientWidth)
		}), $(".modal").on("hidden.bs.modal", function (e) {
			$("header").css("padding-right", "0")
		});
	*/
	$(".offcanvas").on("show.bs.offcanvas", function (e) {
		$("header").css("padding-right", window.innerWidth - document.documentElement.clientWidth)
	}), $(".offcanvas").on("hidden.bs.offcanvas", function (e) {
		$("header").css("padding-right", "0")
	});



var r = 59,
	c = 19,
	n = !0;
if (setInterval(n, 1e3), $("#map").length) {
	ymaps.ready(init);

	function init() {
		var result = document.getElementById('result'),
			// Р Р† РЎРЊРЎвЂљР С•Р в„– Р Р†Р ВµРЎР‚РЎРѓР С‘Р С‘ Р С”Р С•Р С•РЎР‚Р Т‘Р С‘Р Р…Р В°РЎвЂљРЎвЂ№ Р С—РЎР‚Р С•РЎРѓРЎвЂљР С• РЎРЊР В»Р ВµР СР ВµР Р…РЎвЂљРЎвЂ№ Р СР В°РЎРѓРЎРѓР С‘Р Р†Р В° (Р С‘ Р С•Р Р…Р С‘ Р С—Р С•Р СР ВµР Р…РЎРЏР Р…РЎвЂ№ Р СР ВµРЎРѓРЎвЂљР В°Р СР С‘)
			destinations = {
				'Кутузовский проспект, ул. Малая Филевская 26, корпус 1': [55.74332956897456, 37.54239249999996],
			},
			// Р РЋР С•Р В·Р Т‘Р В°Р Р…Р С‘Р Вµ РЎРЊР С”Р В·Р ВµР СР С—Р В»РЎРЏРЎР‚Р В° Р С”Р В°РЎР‚РЎвЂљРЎвЂ№ Р С‘ Р ВµР С–Р С• Р С—РЎР‚Р С‘Р Р†РЎРЏР В·Р С”Р В° Р С” Р С”Р С•Р Р…РЎвЂљР ВµР в„–Р Р…Р ВµРЎР‚РЎС“ РЎРѓ
			// Р В·Р В°Р Т‘Р В°Р Р…Р Р…РЎвЂ№Р С id ("map").
			myMap = new ymaps.Map('map', {
				// Р СџРЎР‚Р С‘ Р С‘Р Р…Р С‘РЎвЂ Р С‘Р В°Р В»Р С‘Р В·Р В°РЎвЂ Р С‘Р С‘ Р С”Р В°РЎР‚РЎвЂљРЎвЂ№ Р С•Р В±РЎРЏР В·Р В°РЎвЂљР ВµР В»РЎРЉР Р…Р С• Р Р…РЎС“Р В¶Р Р…Р С• РЎС“Р С”Р В°Р В·Р В°РЎвЂљРЎРЉ
				// Р ВµРЎвЂ РЎвЂ Р ВµР Р…РЎвЂљРЎР‚ Р С‘ Р С”Р С•РЎРЊРЎвЂћРЎвЂћР С‘РЎвЂ Р С‘Р ВµР Р…РЎвЂљ Р СР В°РЎРѓРЎв‚¬РЎвЂљР В°Р В±Р С‘РЎР‚Р С•Р Р†Р В°Р Р…Р С‘РЎРЏ.
				center: [55.74281513104588, 37.544270046310395], // Р РЋР В°Р Р…Р С”РЎвЂљ-Р СџР ВµРЎвЂљР ВµРЎР‚Р В±РЎС“РЎР‚Р С–
				zoom: 17
			});
		myPlacemark = new ymaps.Placemark(destinations['Кутузовский проспект, ул. Малая Филевская 26, корпус 1'], {
			hintContent: 'Кутузовский проспект, ул. Малая Филевская 26, корпус 1',
			balloonContent: 'Кутузовский проспект, ул. Малая Филевская 26, корпус 1'
		}, {
			// Р С›Р С—РЎвЂ Р С‘Р С‘.
			// Р СњР ВµР С•Р В±РЎвЂ¦Р С•Р Т‘Р С‘Р СР С• РЎС“Р С”Р В°Р В·Р В°РЎвЂљРЎРЉ Р Т‘Р В°Р Р…Р Р…РЎвЂ№Р в„– РЎвЂљР С‘Р С— Р СР В°Р С”Р ВµРЎвЂљР В°.
			iconLayout: 'default#image',
			// Р РЋР Р†Р С•РЎвЂ Р С‘Р В·Р С•Р В±РЎР‚Р В°Р В¶Р ВµР Р…Р С‘Р Вµ Р С‘Р С”Р С•Р Р…Р С”Р С‘ Р СР ВµРЎвЂљР С”Р С‘.
			iconImageHref: 'img/map.svg',
			iconColor: "red",
			iconImageSize: [60, 60],
			iconImageOffset: [-20, -73]
		});

		myPlacemark2 = new ymaps.Placemark(destinations['Кутузовский проспект, ул. Малая Филевская 26, корпус 1'], {
			hintContent: 'Кутузовский проспект, ул. Малая Филевская 26, корпус 1',
			balloonContent: 'Кутузовский проспект, ул. Малая Филевская 26, корпус 1'
		}, {
			// Р С›Р С—РЎвЂ Р С‘Р С‘.
			// Р СњР ВµР С•Р В±РЎвЂ¦Р С•Р Т‘Р С‘Р СР С• РЎС“Р С”Р В°Р В·Р В°РЎвЂљРЎРЉ Р Т‘Р В°Р Р…Р Р…РЎвЂ№Р в„– РЎвЂљР С‘Р С— Р СР В°Р С”Р ВµРЎвЂљР В°.
			iconLayout: 'default#image',
			// Р РЋР Р†Р С•РЎвЂ Р С‘Р В·Р С•Р В±РЎР‚Р В°Р В¶Р ВµР Р…Р С‘Р Вµ Р С‘Р С”Р С•Р Р…Р С”Р С‘ Р СР ВµРЎвЂљР С”Р С‘.
			iconImageHref: 'img/placeholder.svg',
			iconColor: "red",
			iconImageSize: [50, 80],
			iconImageOffset: [-20, -73]
		});
		// Р Р†РЎРѓР Вµ Р С•Р С”
		myMap.geoObjects
			.add(myPlacemark);
		myMap.behaviors.disable('scrollZoom');
		//Р Р…Р В° Р СР С•Р В±Р С‘Р В»РЎРЉР Р…РЎвЂ№РЎвЂ¦ РЎС“РЎРѓРЎвЂљРЎР‚Р С•Р в„–РЎРѓРЎвЂљР Р†Р В°РЎвЂ¦... (Р С—РЎР‚Р С•Р Р†Р ВµРЎР‚РЎРЏР ВµР С Р С—Р С• userAgent Р В±РЎР‚Р В°РЎС“Р В·Р ВµРЎР‚Р В°)
		/*myMap2 = new ymaps.Map('map2', {

			center: [55.74281513104588, 37.544270046310395], // Р РЋР В°Р Р…Р С”РЎвЂљ-Р СџР ВµРЎвЂљР ВµРЎР‚Р В±РЎС“РЎР‚Р С–
			zoom: 17
		});
		myMap2.geoObjects
			.add(myPlacemark2)

		myMap2.behaviors.disable('scrollZoom');
*/
		if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {

			myMap.behaviors.disable('drag');
		}
	}
};
