const dada = new DynamicAdapt("max");
dada.init();

var swiper = new Swiper(".main-slider", {
	slidesPerView: 1,
	slidesPerGroup: 1,
	pagination: {
		el: ".swiper-pagination",
		clickable: true,
	},
});